<template>
	<div class="ztnrbg">
		<div class="index_mv ztnr">
			<leftMeun></leftMeun>
			<div class="right_nr">
				<rightMeun></rightMeun>
				<div class="show2">
					<div class="indexImg22" style="min-height: 200px">
						<div v-if="mztj0">
							<Carousel
								loop
								arrow="never"
								autoplay
								:scale="windowWidth < 990 ? 1 : 457 / 895"
								w="100%"
								v-if="mztj0.length > 0"
							>
								<CarouselItem v-for="(item1, index) in mztj0" :key="index">
									<a v-if="item1.jump == 'url'" :href="item1.url" target="_blank">
										<div class="demo-carousel1">
											<ScaleDiv :scale="457 / 895" :img="item1.thumb"></ScaleDiv>
										</div>
									</a>
									<router-link v-else :to="'/details/cont/' + item1.id">
										<div class="demo-carousel1">
											<ScaleDiv :scale="457 / 895" :img="item1.thumb"></ScaleDiv>
										</div>
									</router-link>
								</CarouselItem>
							</Carousel>
						</div>
						<div v-else>载入中...</div>
					</div>
					<div class="showLists">
						<div class="showLists-content">
							<div class="left-pic">
								<img src="../../assets/img/xintp/bx.png" alt="" />
							</div>
							<div class="right-title">
								<div class="title">2021.09.10-2021.12.25 <span>南方科技大学现时代主题展</span></div>
								<div class="desc">
									关于这次展览的概况信息。关于这次展览的概况信息。关于次展楚的模况紫息。关于这次展览的概况信息。关于站次览的顺况信息。关于站次展冀的顺况情关于这次展览的概况信息。关于这次展览的概况信息。关于次展楚的模况紫息。关于这次展览的概况信息。关于站次览的顺况信息。关于站次展冀的顺况情......
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<newRightMeun></newRightMeun>
		</div>
	</div>
</template>
<script>
import ScaleDiv from '@/components/ScaleDiv.vue';
import leftMeun from '@/components/leftMeun.vue';
import rightMeun from '@/components/rightMeun.vue';
import newRightMeun from '@/components/newRightMeun.vue';

export default {
	components: {
		ScaleDiv,
		leftMeun,
		rightMeun,
		newRightMeun,
	},
	data() {
		return {
			mztj0: [], // 首页详情
			mztj: [],
		};
	},

	name: 'Slider',
	mounted() {},

	methods: {},
	created() {
		var that = this;

		that.$api
			.home()
			.then((res) => {
				that.$emit('getLoad', true);
				that.mztj0 = res.catid47.slice(0, 3); //首页
				that.mztj = res.catid47.slice(3, res.catid47.length); // 每周推荐
				setTimeout(() => {
					this.onResize();
				}, 100);
			})
			.catch((error) => {
				// error
				console.log(error);
			});
	},
};
</script>

<style lang="scss">
.showLists {
	padding-top: 40px;
	.showLists-content {
		padding: 20px 0;
		display: flex;
		border-bottom: 1px solid #cbcbcb;
		&:last-child{
		border: none;
	}
		.left-pic {
			width: 30%;
			height: auto;
			img {
				width: 100%;
				height: 120px;
			}
		}
		.right-title {
			padding-left: 2%;
			width: 68%;
		.title{
			width: 260px;
			font-weight: bold;
			font-size: 18px;
		}
			.desc{
				margin-top: 8px;
				display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			overflow: hidden;
			font-size: 12px;
		}
		}
	
	}
}
</style>
